const routes = [
  {
    path: '/',
    component: () => import('layouts/TunnelLayout.vue'),
    meta: {
      mustBeLoggedOut: true
    },
    children: [
      { name: 'login', path: '', alias: ['home', 'login', 'signup'], component: () => import('pages/Auth/Login.vue') },
      { name: 'loginAs', path: 'auth/:userId', component: () => import('pages/Auth/LoginAs.vue') },
      { name: 'logout', path: 'logout' },
      { name: 'signupCompany', path: 'signup/company', redirect: { name: 'login', query: { tab: 'signup' } } },
      { name: 'signupApplicant', path: 'signup/applicant', redirect: { name: 'login', query: { tab: 'signup' } } },

      { name: 'passwordLost', path: 'password/lost', component: () => import('pages/Auth/PasswordLost.vue') },
      { name: 'passwordSetup', path: 'password/setup/:token/', component: () => import('pages/Auth/PasswordSetup.vue') },
      { name: 'passwordReset', path: 'password/reset/:token/', component: () => import('pages/Auth/PasswordReset.vue') },
      { name: 'memberInvitation', path: 'rejoindre/:token', component: () => import('pages/Auth/MemberInvitation.vue') },
      { name: 'testimonialForm', path: 'entreprise/:companySlug/temoignage/:token/:slug?', component: () => import('pages/Testimonials/TestimonialForm.vue') },
    ]
  },
  {
    path: '/',
    component: () => import('layouts/TunnelLayout.vue'),
    meta: {
      mustBeLogged: true
    },
    children: [
      { name: 'signupCompanyComplete', path: 'signup/company/complete', component: () => import('pages/Auth/SignupCompanyComplete.vue') },
      { name: 'signupApplicantComplete', path: 'signup/applicant/complete', component: () => import('pages/Auth/SignupApplicantComplete.vue') },
      { name: 'embedOffer', path: 'embed/offres-emploi/:id', component: () => import('pages/Offers/Offer.vue'), meta: { hideHeader: true } },

      { name: 'checkoutSubscriptions', path: 'abonnement/selection/', component: () => import('pages/Checkout/Selection.vue') },
      { name: 'checkoutDragon', path: 'abonnement/dragon/:id/commande', redirect: { name: 'checkout', params: { type: 'dragon' } }, },
      { name: 'checkout', path: 'abonnement/:type/commande', component: () => import('pages/Checkout/Review.vue') },
      { name: 'checkoutConfirmation', path: 'abonnement/:type/confirmation/', component: () => import('pages/Checkout/Confirmation.vue') },
      { name: 'shop', path: 'shop/:orderId?', component: () => import('pages/Checkout/Shop.vue') },
    ]
  },
  {
    path: '/',
    component: () => import('layouts/TunnelLayout.vue'),
    children: [
      { name: 'meetingForm', path: 'meeting/:id?', component: () => import('pages/Communication/MeetingForm.vue') },
      { name: 'questionnaireForm', path: 'questionnaire/:token?', component: () => import('pages/Communication/QuestionnaireForm.vue') },
    ]
  },
  {
    name: 'account',
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      mustBeLogged: true,
    },
    children: [
      // General
      { name: 'preview', path: 'apercu', component: () => import('pages/General/Preview.vue') },
      { name: 'dashboard', path: 'dashboard/:token?', component: () => import('pages/General/Dashboard.vue') },
      { name: 'private', path: 'informations-personnelles', component: () => import('pages/General/Private.vue') },
      { name: 'analytics', path: 'analytics', component: () => import('pages/General/Analytics.vue') },

      // Customize
      { name: 'companyForm', path: 'page/:slug?', component: () => import('pages/Customize/CompanyForm.vue') },
      { name: 'tools', path: 'personnaliser/outils', component: () => import('pages/Customize/Tools.vue') },
      { name: 'medias', path: 'personnaliser/medias', component: () => import('pages/Customize/Medias.vue') },
      { name: 'addresses', path: 'personnaliser/bureaux', component: () => import('pages/Customize/Addresses.vue') },
      { name: 'advantages', path: 'personnaliser/avantages', component: () => import('pages/Customize/Advantages.vue') },
      { name: 'categories', path: 'personnaliser/categories', component: () => import('pages/Customize/Categories.vue') },
      { name: 'informations', path: 'personnaliser/informations', component: () => import('pages/Customize/Informations.vue') },
      { name: 'testimonials', path: 'temoignages', component: () => import('pages/Testimonials/Testimonials.vue') },

      // General
      { name: 'applicants', path: 'candidats', component: () => import('pages/Recruitment/ApplicantsListing.vue') },
      { name: 'applications', path: 'candidatures', component: () => import('pages/Recruitment/Applications.vue') },
      { name: 'offers', path: 'offres-emploi', component: () => import('pages/Offers/Offers.vue') },
      { name: 'offer', path: 'offres-emploi/:id', component: () => import('pages/Offers/Offer.vue') },
      { name: 'offerForm', path: 'offre-emploi/:id?/modifier/:slug?', component: () => import('pages/Offers/OfferForm.vue') },
      { name: 'cvtheque', path: 'cvtheque', component: () => import('pages/Recruitment/CVTheque.vue') },
      { name: 'applicantsLists', path: 'listes', component: () => import('pages/Recruitment/ApplicantsLists.vue') },

      // Communication
      { name: 'communication', path: 'communication', component: () => import('pages/Communication/Communication.vue') },
      { name: 'questionnaire', path: 'communication/:id', component: () => import('pages/Communication/Questionnaire.vue') },

      // Subscription
      { name: 'subscription', path: 'abonnement', component: () => import('pages/Subscription/Subscription.vue') },

      // Organizations
      { name: 'implantations', path: 'implantations', component: () => import('pages/Organizations/Implantations.vue') },
      { name: 'team', path: 'utilisateurs', component: () => import('pages/Organizations/Users.vue') },

      // Messages
      { name: 'oldMsg', path: 'messagerie/:conversationId?', redirect: { name: 'messages' }, }, // To remove after updating mails link
      { name: 'messages', path: 'messages/:conversationId?', component: () => import('pages/Messages/Messages.vue') },
      { name: 'messagesTemplates', path: 'modeles/messages', component: () => import('pages/Messages/Templates.vue') },

      // Tools
      { name: 'faq', path: 'faq', component: () => import('pages/Tools/KnowledgeBox.vue') },
      { name: 'help', path: 'aide/:slug?', component: () => import('pages/Tools/Help.vue') },
      { name: 'support', path: 'support', component: () => import('pages/Tools/Support.vue') },
      { name: 'applicantTools', path: 'applicant-tools', component: () => import('pages/Tools/ApplicantTools.vue') },

      // Profile
      { name: 'profile', path: 'profil', component: () => import('pages/Profile/Profile.vue') },
      { name: 'profileForm', path: 'profile/:slug?', component: () => import('pages/Profile/ProfileForm.vue') },

      // false routes that are on web
      { name: 'meetingSignup', path: '/trash', component: () => import('pages/General/Dashboard.vue') },
    ]
  },

  // Always leave these as last ones
  {
    path: '/',
    component: () => import('layouts/TunnelLayout.vue'),
    children: [
      { name: 'redirect', path: 'redirect', component: () => import('pages/Redirect') },
    ]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
